<template>
  <div class="ui sixteen column grid">
    <div class="ui four wide column computer only">
      &nbsp;
    </div>
    <div class="ui eight wide computer eight wide mobile column" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
      <!-- <button class="buttonShadow circular ui icon button" style="color:white;font-size:28px !important;" @click="toggleState"
        :class="[state==='Pause'?'pauseColor':'playColor']" :title="[state==='Pause'?'Play':'Pause']">
        <i style="margin-top:0px !important;" :class="[state==='Pause'?'play':'pause','whiteText icon']"></i>
      </button> -->
      <!-- <i @click="toggleState"  :class="[state!=='Play'?'video play':'pause','teal huge icon']" style="margin:auto;cursor:pointer;"></i> -->
      <audio preload='auto' ref="audio" @pause='onPause' @play='onPlay' @timeupdate='onTimeUpdateListener'
        @loadedmetadata="durationChange" @waiting="waiting" @seeking="seeking" @seeked="print2" @playing="print2" id="myAudio" controls>
        <source :src="oggURL" type="audio/ogg" />
        <source :src="mp3URL" type="audio/mpeg" /> Your browser does not support the audio tag.
      </audio>
      <!-- <div style="font-size:12px;">
        <span v-show="buffering">Buffering...</span>
        <span style="float:right;" class="whiteText">{{humanLength(currentTime)}}/{{humanLength(duration)}}</span>
      </div>
      <vue-slider ref="slider" @callback="updateTimeCallback" :value="currentTime*100" :max="duration*100" tooltip="false" style="padding:0px;padding-top:4px;"
        :processStyle='{"backgroundColor": "#658394"}'></vue-slider> -->
    </div>
    <!-- <div class="ui three wide computer four wide mobile column" style="display: flex;flex-direction: column;justify-content: center;">
      <button @click="back" title="Jump backward 5 seconds" class="buttonShadow circular ui icon teal button">
        <i class="backward icon"></i>
      </button>
      <button @click="forward" title="Jump forward 5 seconds" class="buttonShadow circular ui icon teal button">
        <i class="forward down icon"></i>
      </button>
      <div class="ui compact buttonShadow buttons" style="border-radius:5px;">
        <button @click="speedDec" class="ui compact teal icon left attached button">
          <i class="minus icon"></i>
        </button>
        <div class="ui teal button" style="font-weight:normal;">
          Speed ({{speed}}x)
        </div>
        <button @click="speedInc" class="ui compact teal icon right attached button">
          <i class="add icon"></i>
        </button>
      </div>
    </div> -->
    <div class="ui four wide column computer only">
      &nbsp;
    </div>
  </div>
</template>
<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
// import { humanLength } from '../utility'
export default {
  props: ['podId', 'field', 'uid'],
  mounted () {
    // this.oggURL =
    // 'https://storage.googleapis.com/spext_audio/L0ivsahQ8ohl6ifomoUVKN7FZLr2%2F11e8-2dab-2be6e590-9360-87b758c53fbe%2Fnotes-33d290ce-3657-11e8-8040-020a605b521a.ogg'
    // this.$refs.audio.load()
    this.listenForResult()
  },
  beforeDestroy () {
    var vm = this
    if (vm.resultRef !== null) {
      vm.resultRef.off()
      vm.resultRef = null
    }
    if (vm.state === 'Play') {
      vm.stop()
    }
  },
  data () {
    return {
      buffering: false,
      oggURL: '',
      mp3URL: '',
      duration: 0,
      state: 'Pause',
      speed: 1,
      currentTime: '',
      resultRef: null,
      result: ''
    }
  },
  methods: {
    listenForResult () {
      var vm = this
      if (vm.resultRef !== null) {
        vm.resultRef.off()
        vm.resultRef = null
      }
      var path = vm.uid + '/podcast-info/' + vm.podId + '/' + vm.field
      console.log('path is ', path)

      vm.resultRef = firebase.database().ref(path)
      vm.resultRef.on('value', function (snapshot) {
        console.log('value is', snapshot.val())
        if (snapshot.exists()) {
          vm.resultRef.off()
          var url = snapshot.val()
          var storage = firebase.storage()
          var httpsReference = storage.ref(url)
          httpsReference.getDownloadURL().then(function (newUrl) {
            console.log('url is', newUrl)
            vm.oggURL = newUrl
            vm.$refs.audio.load()
            vm.$parent.mergedTrack = true
            vm.$parent.url = vm.oggURL
          })
        }
      })
    },
    routeToHome () {
      this.$router.replace({ name: 'index' })
    },
    onPause: function () {
      console.log('Pause')
      this.state = 'Pause'
    },
    onPlay: function () {
      console.log('Play')
      this.state = 'Play'
    },
    playAudio () {
      this.state = 'Play'
      this.$refs.audio.play()
    },
    toggleState: function () {
      console.log('Toggling State')
      if (this.state === 'Pause') {
        this.state = 'Play'
        this.$refs.audio.play()
      } else {
        this.state = 'Pause'
        this.$refs.audio.pause()
      }
    },
    stop: function () {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    },
    back: function () {
      this.$refs.audio.currentTime = this.$refs.audio.currentTime - 5
      if (this.$refs.audio.currentTime < 0) {
        this.$refs.audio.currentTime = 0
      }
    },
    forward: function () {
      this.$refs.audio.currentTime = this.$refs.audio.currentTime + 5
    },
    speedInc: function () {
      this.speed = (this.$refs.audio.playbackRate + parseFloat('0.1')).toFixed(
        1
      )
      this.$refs.audio.playbackRate = this.speed
    },
    speedDec: function () {
      this.speed = (this.$refs.audio.playbackRate - parseFloat('0.1')).toFixed(
        1
      )
      if (this.speed < 0) {
        this.speed = 0
      }
      this.$refs.audio.playbackRate = this.speed
    },
    onTimeUpdateListener () {
      // this.$refs.slider.refresh()
      // this.currentTime = this.$refs.audio.currentTime
    },
    durationChange () {
      if (this.$refs.audio) {
        this.duration = this.$refs.audio.duration
      }
    },
    waiting () {
      this.buffering = true
    },
    seeking () {
      this.buffering = true
      console.log('seeking Audio')
      // console.log('onTimeUpdateListener:' + this.$refs.audio.currentTime)
      // Update current time
      // this.$refs.slider.refresh()
      // this.currentTime = this.$refs.audio.currentTime
    },
    print2 () {
      this.buffering = false
      console.log('buffered Audio')
    },
    updateTimeCallback (time) {
      console.log('time updated by slider(click):' + time / 100)
      this.$refs.audio.currentTime = time / 100
    }
  }
}
</script>
<style scoped>
.playColor {
  background-color: #4bdacc !important;
}

.pauseColor {
  background-color: #324851 !important;
}
</style>
